// Here you can add other styles
.messageDontEnter {
    height: 100vh;
    width: 70%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
  
    p {
      font-size: 25px;
      text-align: center;
    }
}

.app-header{
    background-color: #343f47
}